//
// Typography
// ----------

// Headings
//

h1,
.h1 {
  margin-top: $line-height-computed * 0.75;
  margin-bottom: $line-height-computed;
  font-family: $font-family-headline;
  font-size: $font-size-h1 * 0.8;

  @media (min-width: $screen-sm-min) {
    margin-bottom: $line-height-computed;
  }

  @media (min-width: $screen-md-min) {
    font-size: $font-size-h1;
  }
}

h2,
.h2 {
  font-family: $font-family-headline;
  font-size: $font-size-h3 * 0.8;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h3;
  }
}

h3,
.h3 {
  font-family: $font-family-headline;
  font-size: $font-size-h3 * 0.8;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h3;
  }
}

h5,
.h5 {
  font-family: $font-family-headline;
  font-size: 16px;
  margin-bottom: 12px;
}

.head-top-space {
  margin-top: 25px;
}

.head-border {
  padding-bottom: 5px;
  border-bottom: $brand-border-width solid $brand-primary-light;
  margin-bottom: $line-height-computed * 1.1;
}

// Paragraphs
//

p {
  font-weight: $font-weight-base;

  &:last-child {
    margin-bottom: $line-height-computed;
  }

  &.no-space:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: $font-weight-base;
  }
}

div {
  font-weight: $font-weight-base;
}

b {
  font-weight: $font-weight-bold;
}

@media (min-width: $screen-md-min) {
  p.two-thirds {
    width: 70%;
  }
}

// List options

ul.list-spaced {
  li {
    margin-bottom: 4px;
  }
}

li {
  font-weight: $font-weight-light;

  .list-inline &,
  .navbar-nav & {
    font-weight: $font-weight-base;
  }
}

// Description Lists

dt {
  font-weight: $font-weight-semi-bold;

  &:after {
    content: ':';
  }
}

// Allow left aligning the labels in the horizontal dl's.
.dl-horizontal.dl-left {
  @media (min-width: $dl-horizontal-breakpoint) {
    dt {
      text-align: left;
    }
  }
}
