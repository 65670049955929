//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 86.5%); // #ddd

$brand-primary: #be2026; // fire red  - 190, 32, 38
$brand-success: #44bc63;
$brand-info: #0097a7;
$brand-warning: #ef6c00;
$brand-danger: #dd2126;

$brand-primary-light: #be424a; // raspberry pastel  - 190, 66, 74

//== Font weights & families

$weight-thin: 100;
//$weight-extra-light: 200;
$weight-light: 300;
$weight-normal: 400;
$weight-regular: 400; // Alias for normal
//$weight-medium: 500;
$weight-semi-bold: 600;
$weight-bold: 700;
//$weight-extra-bold: 800;
//$weight-ultra-bold: 900;

$font-family-base: 'Roboto', Helvetica, Arial, sans-serif;
$font-family-headline: 'Roboto Condensed', Arial, sans-serif;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: #121212;
//** Background color for overlays on top of `<body>`.
$body-bg-level1: #111;
//** Background color for 2nd tier overlays on top of `<body>`.
$body-bg-level2: #222;
//** Background color for 3rd tier overlays on top of `<body>`.
$body-bg-level3: #333;
//** Global text color on `<body>`.
$text-color: #fff;

//** Global textual link color.
$link-color: #fff;
//** Link hover color.
$link-hover-color: #fff;
//** Link hover decoration.
$link-hover-decoration: none;

//** Light theme pages
$light-body-bg: #fff;
$light-text-color: $gray-dark;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: $font-family-base;

// Maintain the Bootstrap default base font
$font-family-bootstrap: 'Helvetica Neue', Helvetica, Arial, sans-serif;

$font-size-xs: 10px;
$font-size-small: 12px;
$font-size-base: 14px;
$font-size-mid: 15px;
$font-size-large: 16px;

// For certain fonts shifting the weight up or down is convenient. By always using the
// variables on the left to indicate intent the variables on the right can be shifted
// to provide the best representation of the content for the selected font-family.
$font-weight-thin: $weight-light;
$font-weight-light: $weight-light;
$font-weight-base: $weight-light;
$font-weight-semi-bold: $weight-regular;
$font-weight-bold: $weight-regular;

$font-size-h1: 28px;
$font-size-h2: 25px;
$font-size-h3: 21px;
$font-size-h4: 18px;

//== Iconography
//

// Get the Glyphicons font from the CDN rather than our server.
$icon-font-path: '//cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/3.3.5/fonts/';

//== Components
//
//## Define common padding and border radius sizes and more.

$border-radius-base: 0;
$border-radius-small: 0;
$border-radius-large: 0;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 50px;
$navbar-margin-bottom: 0;
$navbar-padding-v-offset: -1px;
$navbar-toggle-icon-bar-height: 3px;
$navbar-collapse-max-height: 485px;

// The Flix Premiere web-app navbar size
$navbar-mobile-height: 52px;
$navbar-desktop-height: 75px;

//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: $gray-lighter;
$navbar-inverse-bg: #000;
$navbar-inverse-border: $brand-primary-light;

// Inverted navbar links
$navbar-inverse-link-color: $gray-lighter;
$navbar-inverse-link-disabled-color: $gray-light;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-hover-bg: $gray;

//== Pills
$nav-pills-border-radius: 4px;
$nav-pills-active-link-hover-bg: darken($brand-primary, 5%);
$nav-pills-active-link-hover-color: #fff;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text: #13aa5b;

//== Tooltips
//
//##

//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: $body-bg-level3;

//== Popovers
//
//##

//** Popover body background color
$popover-bg: $body-bg-level3;
//** Popover maximum width
$popover-max-width: 100%;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Background color used for `.table-striped`.
$table-bg-accent: darken($body-bg-level2, 3%);
//** Background color used for `.table-hover`.
$table-bg-hover: lighten($body-bg-level1, 12%);
$table-bg-active: $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color: #555;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: $font-weight-semi-bold;
$btn-text-transform: uppercase;

$btn-default-color: #fff;
$btn-default-bg: #272727;
$btn-default-border: #272727;

//== Forms
//
//##

//** Default `.form-control` height
$input-height-base: 34px;

//== Container system
//
//## Panelled images have a smaller gutter than the default grid.

$grid-gutter-xs: 6px;
$img-list-gutter: 10px;
$actors-list-gutter: $grid-gutter-xs;
$actors-list-columns: 10;

//## Width of a border separating columns with .col-<size>-separator.
$col-separator-width: 1px;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-danger-text: #e53935;

//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;

//== Modals
//
//##

////** Padding applied to the modal body
//$modal-inner-padding:         12px;

//** Padding applied to the modal title
$modal-title-padding: 12px;

//** Background color of modal content area
$modal-content-bg: $body-bg-level2;

//** Modal header border color
$modal-header-border-color: $brand-primary;

$modal-sm-plus: 430px;

$modal-img-padding: 12px;
$modal-img-size: 700px;
$modal-video-size: 800px;

$video-aspect-ratio: calc(16 / 9);

//== Carousel
//
//##

$carousel-indicator-bg: #fff;
$carousel-indicator-active-bg: $brand-primary;
$carousel-indicator-border-color: transparent;

//== Close
//
//##

$close-font-weight: $font-weight-base;
$close-font-size: 27px;
$close-color: $gray-lighter;
$close-text-shadow: none; //0 1px 0 #fff !default;

//== Type
//
//##

//** Width of horizontal description list titles
$dl-horizontal-offset: 130px;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: 315px;

//** Horizontal line color.
$hr-border: $brand-primary-light;
//** Horizontal line color in modals.
$hr-border-gray: $gray-light;
//** Horizontal line width.
$hr-width: 1px;
$brand-border-width: 1px;
