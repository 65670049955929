.rc-slider,
.rc-slider * {
  /* Prevent Safari starting a selection on long-press */
  user-select: none;
}

.rc-slider:hover {
  cursor: pointer;
}

.rc-slider-handle,
.rc-slider-handle:hover,
.rc-slider-handle:focus,
.rc-slider-handle:active {
  box-shadow: none !important;
  cursor: pointer;
  /* z-index to go over the mouse hover marker */
  z-index: 220;
}

.rc-slider-disabled {
  background-color: transparent !important;
}

.rc-slider.rc-slider-disabled:hover,
.rc-slider-disabled .rc-slider-rail:hover,
.rc-slider-disabled .rc-slider-track:hover,
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-handle:hover,
.rc-slider-disabled .rc-slider-handle:focus,
.rc-slider-disabled .rc-slider-handle:active {
  cursor: default;
}

.rc-slider-wider .rc-slider-track,
.rc-slider-wider .rc-slider-rail {
  height: 8px;
  margin-top: -2px;
}
