
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
.Snackbar {
  box-shadow: 0 -3px 22px $body-bg-level2;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  text-align: center;
  z-index: $zindex-popover;
}

.close {
  position: absolute;
  right: $grid-gutter-width * 0.5;
  top: 0;
  opacity: 1;
}

.title {
  padding: 0 $grid-gutter-width; // Horizontal padding keeps text out from under .close
}

.header {
  background: darken($body-bg-level2, 1%);
  padding: $padding-base-vertical 0;
  font-size: $font-size-large;
  line-height: 25px;
}

.body {
  background: $body-bg-level3;
  padding: $padding-base-vertical 0;
  line-height: 1.7em;
  min-height: 70px;

  p {
    margin-bottom: 0;
  }
}

.danger {
  .header {
    background: darken($brand-danger, 5%);
  }
}

.info {
  .header {
    background: #3f7aa4;
  }
}
