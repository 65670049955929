
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
// ### ReactCSSTransitionReplace

// Slide in from left
//
// Move the entire object from off the left of the screen into view.

:global {
  .slide-left-enter {
    transform: translate3d(-100%, 0, 0);

    &.slide-left-enter-active {
      transform: translate3d(0, 0, 0);
      transition: transform 0.2s ease-out;
    }
  }

  .slide-left-leave {
    transform: translate3d(0, 0, 0);

    &.slide-left-leave-active {
      transform: translate3d(-100%, 0, 0);
      transition: transform 0.15s ease-in;
    }
  }
}
