
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
.modalPage {
  :global(.modal) {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
    z-index: 1;
    display: block;
  }

  :global(.modal-dialog) {
    margin-top: 0;
    margin-bottom: 0;

    @media (min-width: $screen-sm-min) {
      margin-top: $line-height-computed * 1.5;
      margin-bottom: $line-height-computed * 1.5;
    }
  }

  &.vOffset {
    margin-bottom: 110px;

    @media (min-width: $screen-sm-min) {
      margin-top: 110px;
    }
  }

  &.overflow {
    :global(.modal) {
      overflow: visible;
    }
  }
}
