
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import 'bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/mixins';

// Reset and dependencies
@import 'bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/print';

// Core CSS
@import 'bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/type';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/grid';

// Components
@import 'bootstrap-sass/assets/stylesheets/bootstrap/navs';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/close';

// Components w/ JavaScript
@import 'bootstrap-sass/assets/stylesheets/bootstrap/modals';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/tooltip';

// Utility classes
@import 'bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

// Override selected styles
@import 'grid';
@import 'scaffolding';
@import 'type';
