// Container
// ----

// Small gutter for xs grid

@media (max-width: $screen-xs-max) {
  // Included in the Container component styles
  //.container {
  //  padding-left: $grid-gutter-xs;
  //  padding-right: $grid-gutter-xs;
  //}

  .row {
    margin-left: -$grid-gutter-xs * 0.5;
    margin-right: -$grid-gutter-xs * 0.5;
  }

  [class*='col-'] {
    padding-left: $grid-gutter-xs * 0.5;
    padding-right: $grid-gutter-xs * 0.5;
    margin-bottom: $grid-gutter-xs;
  }
}
