
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zindex-popover;
  max-width: $popover-max-width;

  // Our parent element can be arbitrary since popovers are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text;
  font-size: $font-size-base;

  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-color: $body-bg-level2;
  background-clip: padding-box;

  // On the lighter background the button should be even lighter.
  :global(.btn-default) {
    &,
    &:hover,
    &:hover:active,
    &:focus {
      background-color: lighten($btn-default-bg, 15%);
      border-color: lighten($btn-default-border, 15%);
    }
  }
}

// Right align with the target rather than left align
.right {
  @media (min-width: $screen-sm-min) {
    left: auto !important;
    right: 0;
  }
}

.padded {
  padding: $modal-inner-padding;
}
