
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
.button {
  background: transparent;
  position: relative;
  margin: 7px 0 7px 7px;
  padding: 10px;
  border: 0;
  &:focus {
    outline: 0;
  }

  &.active {
    background: #555;
  }
}

.iconBar {
  display: block;
  width: 22px;
  height: 2px;
  background: #fff;
  border-radius: 1px;
}

.iconBar + .iconBar {
  margin-top: 4px;
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
