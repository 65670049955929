
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
.footer {
  text-align: center;
  border-top: 1px solid $hr-border-gray;
  padding: $line-height-computed 0 ($line-height-computed * 2.5);
}

.footerNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: $font-size-mid;
  line-height: 2;
  margin: 0 auto 20px;

  > a {
    font-family: $font-family-headline;
    font-weight: $font-weight-base;
    font-size: 16px;
    padding-right: 6px;
    padding-left: 6px;
  }
}

.storeButtons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto 10px;
  max-width: 250px;
  @media (min-width: $screen-sm-min) {
    max-width: 1000px;
  }
}

.copyright {
  font-size: $font-size-small + 1px;
  margin-bottom: $line-height-computed * 1.5;
}

.fpTag {
  display: inline-block;
  background: $gray-lighter;
  border-radius: 2px;
  width: 21px;
  line-height: 15px;
  font-size: 15px;
  font-weight: $weight-semi-bold;
  color: #000;
  text-align: center;
}
