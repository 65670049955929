
                     @import "src/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";
                     @import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
// ### ReactCSSTransitionReplace

// Slide in from bottom
//
// Move the entire object from off the bottom of the screen into view.

:global {
  $slide-duration: 0.25s;
  .slide-bottom-leave {
    transform: translate3d(0, 0, 0);

    &.slide-bottom-leave-active {
      transform: translate3d(0, 200px, 0);
      transition: transform $slide-duration ease-in;
    }
  }

  .slide-bottom-enter {
    transform: translate3d(0, 200px, 0);

    &.slide-bottom-enter-active {
      transform: translate3d(0, 0, 0);
      transition: transform $slide-duration ease-in;
    }
  }
}
