//
// Scaffolding
// -----------

// By forcing these to 100% height child components can fill available height
// by applying height: 100% themselves.
html,
body,
#root,
#root > div {
  height: 100%;
}

html {
  font-size: 100%;
}

// Position all content below the static top navbar.
.page-body {
  background-color: $body-bg;
  height: 100%;
  z-index: 1;
}

.page-body-short {
  min-height: 0;
}

// Use to conveniently specify a positioning anchor element.
.relative {
  position: relative;
}

@media (max-width: $screen-xs-max) {
  .brand-border-top-xs {
    border-top: $brand-primary-light solid $brand-border-width;
  }
}

p.empty-text {
  margin-top: $line-height-computed * 3;
  height: 300px;
}

// Links
//
// Links without an href should still appear linkable. The `linkable` and `clickable`
// classes make any element appear click-/linkable by changing the cursor.

a,
.linkable,
.clickable {
  cursor: pointer;
}

a.plain {
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

a.underline,
.underline {
  text-decoration: underline;
}

a.bolder {
  font-weight: $font-weight-base;
}

a.bold {
  font-weight: $font-weight-semi-bold;
}

a.underline,
.underline,
a.bolder,
a.bold {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

a:focus {
  outline: none;
}

li a,
a.unstyled {
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.h1-links {
  display: inline-block;
  padding-top: 7px;

  @media (min-width: $screen-sm-min) {
    padding-top: 9px;
  }

  a {
    color: $text-color;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

// Content padding and spacing
//

.no-space {
  margin-bottom: 0;
}

.no-top-space {
  margin-top: 0;
}

.spaced {
  margin-bottom: $line-height-computed;
}

.spaced-dot5 {
  margin-bottom: $line-height-computed * 0.5;
}

.spaced-dot75 {
  margin-bottom: $line-height-computed * 0.75;
}

.spaced-1dot5 {
  margin-bottom: $line-height-computed * 1.5;
}

.spaced-double {
  margin-bottom: $line-height-computed * 2;
}

// Horizontal rules
//

hr {
  margin: $line-height-computed * 0.5 0 $line-height-computed * 0.75;
  border-top-width: $hr-width;
}

hr.gray {
  border-top-color: $hr-border-gray;
}

hr.double-spaced {
  margin-top: $line-height-computed * 2;
  margin-bottom: $line-height-computed * 2;
}
